<template>
	<v-card>
		<!-- <s-select-definition hidden @change="changeLayette($event)" label="Nro Canastilla" :def="1259"  /> -->
		<s-toolbar label="Tranferencia Congelado" dark color="#8e8f91" save @save="save()"></s-toolbar>
		<v-container>
			<!-- <v-card flat> -->
                <!-- <v-row>
                    <v-col cols="12">
                        <s-toolbar label="Tranferencia Congelado" save @save="save()"></s-toolbar>
                    </v-col>
                    
                </v-row> -->
				<v-row justify="center" style="margin: auto">
					<div class="col-lg-3 col-md-4 col-sm-4">
						<b>
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Buscar"
								single-line
								hide-details
							></v-text-field>
						</b>
					</div>

					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha Inicio"
							v-model="BeginDate"
						></s-date>
					</v-col>
					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha Fin"
							v-model="EndDate"
						></s-date>
					</v-col>
				</v-row>
			<!-- </v-card> -->

			<!-- <v-card dense style="margin-top: 4px"> -->
				<v-row style="margin:auto">
                    <v-col cols="12" lg="3">
                        <s-text label="N° Guia Salida Interna" :mask="maskFormat" v-model="item.FrzNumberGuide"></s-text>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <s-text decimal label="Peso Neto" v-model="item.FrzWeighedNet"></s-text>
                    </v-col>
                     <v-col cols="12" lg="3">
                        <s-text decimal label="Peso Bruto" v-model="item.FrzWeighedGross"></s-text>
                    </v-col>
                     <v-col cols="12" lg="3">
                        <v-switch
                            style="margin-top: 10px"
                            label="Congelado"
                            v-model="item.FrzCheckFrozen"
                            disabled
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" lg="12" >
                        <s-textarea style="margin-top: -30px" label="Motivo" v-model="item.FrzDescription"></s-textarea>
                    </v-col>
                   
                </v-row>
                <v-row style="margin-top: -10px">
					<v-col cols="12">
						<v-data-table
							item-key="LytID"
							dense
							:headers="headers"
							:items="itemsAll"
							:search="search"
                            show-select
                             v-model="selected"
                             disable-sort
						>

							<template v-slot:item.RcfID="row">
								<v-chip small dark outlined :color="'info'">
									{{ row.item.RcfID }}
								</v-chip>
							</template>

							<template v-slot:item.TnkDifference="row">
								<v-chip small dark outlined :color="'red'">
									{{ row.item.TnkDifference }}
								</v-chip>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			<!-- </v-card> -->
		</v-container>
	</v-card>
</template>

<script>
	import _sLayetteService from "../../../services/FreshProduction/PrfLayetteService";
    import _sGenParam from "../../../services/General/ParameterService";

	export default {
		components: {},
		data() {
			return {
                selected: [],
                checkFrozen: 1,
                maskFormat: "",
				headers: [
					{ text: "Canas. Code ", value: "LytID", width: 100  },
					{ text: "Canastilla", value: "TypeLayetteName", width: 100 },
					{ text: "Cant. Jabas", value: "LppQuantityJaba", width: 80, align: "center"},
					{ text: "Lote Producción", value: "RcfID", width: 300 },
					{ text: "Frente", value: "TypeFrontLayette", width: 100 },
					{ text: "Tiempo", value: "TypeTimeHidro", width: 100 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtName", width: 100 },
					{ text: "Tanque", value: "TypeTankName", width: 100 },
					{
						text: "Linea Proceso",
						value: "TypeLineProcessName",
						width: 100,
					},
					{
						text: "Numero Tratamiento",
						value: "TnkNumberTreatment",
						width: 100,
					},
					{ text: "Posicion", value: "TnkPosition", width: 100 },
					{ text: "Hora Inicio", value: "TnkHourEntry", width: 150 },
					{ text: "Hora Fin", value: "TnkHourExit", width: 150 },
					{
						text: "Tiempo Real (Min)",
						value: "TnkDifference",
						width: 100,
					},
				],
				itemsAll: [],
				BeginDate: null,
				EndDate: null,
				search: "",
                item: {
                    FrzNumberGuide: null,
                    FrzWeighedNet: null,
                    FrzWeighedGross: null,
                    FrzDescription: null,
                    FrzCheckFrozen: 1,
                },
			};
		},
        created() {
            this.initializeParam();
        },
        watch:{
            BeginDate(){
                this.initialize();
            },

            EndDate(){
                this.initialize();
            }
        },

		methods: {
			initialize() {
				_sLayetteService
					.GetLayetteFormed(
						{ BeginDate: this.BeginDate, EndDate: this.EndDate },
						this.$fun.getUserID(),
					)
					.then(data => {
						if (data.status == 200) {
							this.itemsAll = data.data;

							this.itemsAll.forEach(element => {
								element.TnkHourEntry = this.$moment(
									element.TnkHourEntry,
								).format(this.$const.FormatDateTimeDB);
								element.TnkHourExit = this.$moment(
									element.TnkHourExit,
								).format(this.$const.FormatDateTimeDB);
								element.TnkDifference =
									this.$fun.isDifferenceMinutes(
										element.TnkHourEntry,
										element.TnkHourExit,
									) * -1;
							});
						}
					});
			},

            initializeParam() {
                _sGenParam
					.search({ PrmName: "FormatoNumeroGuiaSalidaHidroterm" }, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							this.maskFormat = resp.data.PrmValue;
						}
					});
            },

			save() {


                if(this.item.FrzNumberGuide == '' || this.item.FrzNumberGuide == null){
                    this.$fun.alert("Ingrese numero Guia Salida", "warning")
                    return;
                }

                if(this.item.FrzWeighedNet == 0 || this.item.FrzWeighedNet == null){
                    this.$fun.alert("El peso neto debe ser mayor a 0", "warning")
                    return;
                }

                if(this.item.FrzWeighedGross == 0 || this.item.FrzWeighedGross == null){
                    this.$fun.alert("El peso bruto debe ser mayor a 0", "warning")
                    return;
                }

				if(this.item.FrzWeighedGross < this.item.FrzWeighedNet){
                    this.$fun.alert("El peso bruto no puede ser menor que peso Neto", "warning")
                    return;
                }

                if(this.item.FrzDescription == 0 || this.item.FrzDescription == null){
                    this.$fun.alert("Ingrese un motivo", "warning")
                    return;
                }

                if(this.selected.length == 0) {
                    this.$fun.alert("No existen item seleccionados", "warning")
                    return;
                }

                this.selected.forEach(element => {
                    element.SecStatus = 1
                    element.UsrCreateID = this.$fun.getUserID();
                });

                this.item.SecStatus = 1
                this.item.UsrCreateID = this.$fun.getUserID();
                this.item.itemsDetail = this.selected;

                console.log("Guardar",this.item);

				
				this.$fun.alert("Seguro de enviar Canastilla a Congelado ?", "question").then(r => {
					if(r.value){
						_sLayetteService.saveFrozen(this.item, this.$fun.getUserID()).then(data =>{
							if(data.status == 200){
                                this.initialize()                            
								this.$fun.alert("Enviado con exito", "success")
								this.item = {}
							}
						})
					}
				})

			},
		},
	};
</script>
